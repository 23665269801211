<template>
  <modal class="setMealModel" :isShow="isShow" @keyup.esc="hide">
    <div class="header">{{setMealInfo?.Eat_TcName}}</div>
    <div class="modal-body clearfix">
        <div class="left-nav" ref="nav" >
            <div class="nav-li selected" data-index="0"><span>全部</span></div>
            <div class="nav-li" v-for="(item,index) in setMealInfo?.SetMealKinds" :key="item" :data-index="index"><span>{{item.Kind_Name}}</span></div>
        </div>
        <div class="right-box">
            <div class="meal-box" v-mouse-scroll v-nav-roll="{nav:'nav'}">
                <div class="pane-box" v-for="item in setMealInfo?.SetMealKinds" :key="item">
                    <div class="title">
                        {{item.Kind_Name}}<span>（必选项，最少选{{item.Select_Number}}份，最多选{{item.Count_Number}}份）</span>
                    </div>
                    <div class="item-box">
                        <div class="item-li selected" v-for="set in item.SetMealDetails" :key="set">
                            <div class="tit" :class="{selected:set.Is_Selected==true}">
                                <div class="content"> 
                                    <span v-if="set.Food_Number>0">{{set.Food_Number}}<b class="unit">/{{set.Food_Size}}</b></span>
                                    {{set.Food_Name}}<span v-if="set.Food_TcPrice>0">（加价：{{set.Food_TcPrice}}元）</span>
                                </div>
                                <i class="iconfont icon-gou-fine"></i>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    </div>
    <div class="footer-box">
        <button class="btn btn-cancel" @click="hide()">取消</button>
    </div>
  </modal>
</template>

<script>
/**套餐 */
export default {
    name:"setMealModel",
    data(){
        return {
           
        }
    },
    props:{
        isShow:{
            type:Boolean,
            default:false
        },
        setMealInfo:{
            type:Object,
            default:null
        }
    },
    watch:{
        isShow(newVal){
            if(newVal){
                this.$nextTick(()=>{
                    this.$refs.nav.children[0].dispatchEvent(new KeyboardEvent("click"));
                })
            }
        }
    },
    mounted(){
    },
    methods:{
        hide(){
            this.$emit("closeModel")
        },
    }
}
</script>

<style lang="sass" scoped>
@import "./setMealModel.scss";
</style>